<template>
  <section class="me" :class="{embedded: embedded}">
    <div>
      <div class="box" :class="isEditing ? 'box-warning' : 'box-primary'">
        <template v-if="mode == 'editor'">
          <div class="box-header">
            <span
              :class="isEditing ? 'clicable-title' : ''"
              @click.stop.prevent="$emit('panelProperties')"
            >
              <i v-if="panel.icon" :class="panel.icon"></i>
              <span> {{ $t(panel.title) }} </span>
            </span>
            <slot name="toolbar"></slot>
          </div>
        </template>
        <div class="box-header with-border" v-else>
          <span class="box-title">
            <i v-if="panel.icon" :class="panel.icon"></i>
            <span> {{ $t(panel.title) }} </span>
          </span>
          <slot name="toolbar"></slot>
        </div>
        <div class="box-body" v-if="connector">
          <div class="overlay overlay-loading" v-if="busy">
            <i class="fa fa-refresh fa-spin"></i>
          </div>
          <form>
            <div class="row" v-for="(field, ix) in fields" :key="ix">
              <div class="col-sm-12 col-md-6 col-md-offset-3 field-container">
                <div class="form-group" :class="{'has-error': hasError(field)}">
                  <label
                    :title="
                      field.required
                        ? field.title +
                          ' (' +
                          $tc('required', 1).toLowerCase() +
                          ')'
                        : field.title
                    "
                    :for="`field_${field.name}`"
                    :class="field.required ? 'text-danger' : ''"
                    style="width:100%"
                    >{{ field.title }} <sup v-if="field.required">*</sup>
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control"
                      type="text"
                      v-model="field.value"
                      :placeholder="field.initialValue"
                      :id="`field_${field.name}`"
                      :required="field.required"
                    />
                    <div
                      class="input-group-addon btn"
                      :title="$t('restore')"
                      @click.stop.prevent="
                        setFieldValue(field, field.initialValue, true)
                      "
                    >
                      <i class="fa fa-undo"></i>
                    </div>
                    <div
                      class="input-group-addon btn"
                      :title="$t('clear')"
                      @click.stop.prevent="setFieldValue(field, '', true)"
                    >
                      <i class="fa fa-close"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="box-footer">
          <template v-for="item in controlList">
            <button
              v-if="item.show"
              :class="item.class"
              :key="item.id"
              :title="$t(item.hint)"
              :disabled="busy || item.disabled"
              @click.stop.prevent="onCustomEvent(item, 'click')"
            >
              <div class="hidden-lg hidden-md">
                <i :class="item.icon" v-if="item.icon"></i>
                <span v-if="!item.icon">
                  {{ $t(item.label.getCharAt(0)) }}
                </span>
              </div>
              <div class="hidden-sm hidden-xs">
                <i :class="item.icon" v-if="item.icon"></i>
                <span>
                  {{ $t(item.label) }}
                </span>
              </div>
            </button>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {isEqual} from "lodash";
import ConnectorService from "@/services/connector.js";
export default {
  name: "EquipmentExtendedPropertiesPanel",
  components: {},
  data() {
    return {
      connector: {},
      fields: [],
      busy: false
    };
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => null
    },
    panel: {
      type: Object,
      required: false,
      default: () => null
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"];
    },
    baseModelConnector() {
      return (
        (this?.equipment?.base_model_id &&
          (this.$store.getters["dashboard/connectorList"] || []).find(
            ({id}) => parseInt(id) == parseInt(this.equipment.base_model_id)
          )) ||
        null
      );
    },
    controlList() {
      return (this?.panel?.options?.controls || []).map((i) => {
        if (
          (i?.on?.click?.actions || []).some(({type}) => type == "form:submit")
        ) {
          i.disabled = !this.isValid;
        } else {
          i.disabled = false;
        }
        return i;
      });
    },
    isValid() {
      return (
        this.isDirty &&
        !(this.fields || []).some((field) => this.hasError(field))
      );
    },
    connectorProperties() {
      // this is immutable till save it
      return this.connector?.user_data?.extended_properties || {};
    },
    payload() {
      let payload = {};
      (this.fields || []).forEach((field) => {
        payload[field.name] = field.value;
      });
      return payload;
    },
    isDirty() {
      return !isEqual(this.payload, this.connectorProperties);
    },
    hasUserAccessTo() {
      return this.$store.getters["user/hasUserAccessTo"];
    },
    editorProperties() {
      return (
        (this.isEditing && {
          showOptionalFields: this?.panel?.options?.showOptionalFields ?? false,
          showRequiredFields: this?.panel?.options?.showRequiredFields ?? false
        }) ||
        null
      );
    }
  },
  watch: {
    editorProperties(n, o) {
      if (!this.isEditing && n == o) return;
      this.setup();
    },
    isDirty(n, o) {
      if (isEqual(n, o)) return;
      this.$store.dispatch("dashboard/setDirtyForms", {
        extendedPropertiesForm: n
      });
    }
  },
  methods: {
    setFieldValue(field, value, focus) {
      if (!field || value === undefined) return;
      field.value = value;
      if (focus) {
        this.$nextTick(() => {
          let el = document.getElementById(`field_${field.name}`);
          if (el) el.focus();
        });
      }
    },
    hasError(field) {
      return field.required && field.value === "" ? true : false;
    },
    onCustomEvent(control, eventName) {
      if (this.mode != "viewer") return;
      let evt = (control?.on || {})[eventName] || null;
      if (evt?.actions?.length && this.hasUserAccessTo(evt.process_area_id)) {
        if (evt?.confirmation?.enabled) {
          this.$swal(evt.confirmation).then((confirm) => {
            if (!confirm) return;
            this.triggerEventActions(control, eventName);
          });
        } else {
          this.triggerEventActions(control, eventName);
        }
      }
    },
    triggerEventActions(control, eventName) {
      ((control.on || {})[eventName]?.actions || []).forEach((action) => {
        this.triggerAction(action);
      });
    },
    triggerAction(action) {
      // TODO: in future this all be treated by the global command action manager
      if (this.mode == "editor" || !action?.type) return;
      let command = action.type.split(":")[1];
      switch (command) {
        case "clear":
          this.onFormClear();
          break;
        case "reset":
          this.onFormReset();
          this.$emit("reset");
          break;
        case "submit":
          this.onFormSubmit();
          break;
        default:
          break;
      }
    },
    onFormClear() {
      (this.fields || []).forEach((field, ix) => {
        this.setFieldValue(field, "", ix == 0); // focus on first field
      });
    },
    onFormReset() {
      this.restoreValues();
      this.setFieldValue(this.fields[0], this.fields[0].value, true); // focus on first field
    },
    onFormSubmit() {
      if (!this.isValid || this.busy) return;
      this.busy = true;
      this.service
        .get(this.connector.id)
        .then((ret) => {
          this.service
            .save({
              id: ret.id,
              etag: ret.etag,
              user_data: {
                ...ret.user_data,
                extended_properties: this.payload
              }
            })
            .then((ret) => {
              this.busy = false;
              let connector = structuredClone(ret);
              this.$store.dispatch("dashboard/setConnectorValue", [connector]);
              this.$store.dispatch("dashboard/setConnectorPropertyValue", {
                connector_id: connector.id,
                properties: connector.user_data.extended_properties
              });
              this.connector = connector;
              this.restoreValues();
              // console.log(ret.user_data.extended_properties);
            })
            .catch((err) => {
              this.busy = false;
              console.error(err);
            });
        })
        .catch((err) => {
          this.busy = false;
          console.error(err);
        });
    },
    restoreValues() {
      (this.fields || []).forEach((field) => {
        if (field.name in this.connectorProperties) {
          let value = this.connectorProperties[field.name] || "";
          field.value = field.required && value === "" ? field.value : value;
          field.initialValue = field.value;
        }
      });
    },
    setup() {
      if (!this.contract || !this.connector) return;
      const _buildFields = (extended_properties) => {
        let fields = [];
        let ix = 0;
        for (var p in extended_properties || {}) {
          if (p == "notifications") continue;
          let field = {
            index: ix,
            name: p,
            title: p,
            required: false,
            value: ""
          };
          var content = extended_properties[p];
          if (typeof content == "object") {
            field = {...field, ...content};
          }
          if (field.name in this.connectorProperties) {
            field.value = this.connectorProperties[field.name];
          } else {
            field.value =
              content?.value !== undefined ? content.value : content;
          }
          field.title = field.title || field.name;
          if (this?.panel?.options?.showRequiredFields && field.required) {
            fields.push(field);
            ix++;
          } else if (
            this?.panel?.options?.showOptionalFields &&
            !field.required
          ) {
            fields.push(field);
            ix++;
          }
        }
        fields.sort((a, b) => a.index - b.index);
        // this.$set(this, "fields", fields);
        this.fields = fields;
      };
      let baseModel = this.connector.base_model
        ? this.connector
        : this.baseModelConnector;
      if (baseModel?.portal_data?.extended_properties?.length) {
        // build model fields
        _buildFields(baseModel.portal_data.extended_properties);
      } else if (
        this?.contract?.portal_data?.equipment_extended_properties?.length
      ) {
        // contract based fields
        _buildFields(this?.contract.portal_data.equipment_extended_properties);
      } else {
        // legacy config.json fields
        _buildFields(this.$root.config.equipment_extended_properties);
      }
      this.restoreValues();
    }
  },
  created() {
    this.connector = structuredClone(this.equipment);
    this.setup();
  },
  beforeCreate() {
    this.service = new ConnectorService();
    this.embedded = window.location != window.parent.location;
  },
  beforeDestroy() {
    this.service = null;
  }
};
</script>

<style scoped>
.me {
  width: 100%;
  margin: 0;
  min-height: inherit;
  position: relative;
}

.me > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.box {
  height: 100%;
}

.box > .box-header {
  padding: 5px;
  min-height: 30px;
}

.box > .box-body {
  position: relative;
  height: calc(100% - 90px);
  width: 100%;
  overflow: hidden auto;
  padding: 0 10px 30px 10px;
}

.box > .box-footer {
  clear: both;
  overflow-x: auto;
}

.box > .box-footer > .btn {
  margin: 0 6px;
  max-width: 100px;
  padding: 6px 0;
}

.box > .box-footer > .btn > .hidden-sm,
.box > .box-footer > .btn > .hidden-sm {
  min-width: 100px;
}
.box > .box-footer > .btn > .hidden-lg,
.box > .box-footer > .btn > .hidden-md {
  min-width: 32px;
}

.box > .box-footer > .btn > i {
  margin-right: 5px;
}

.box > .box-body > form {
  margin: 30px 10px;
}

.overlay-loading {
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 80px;
  text-align: center;
  margin-top: 2%;
  color: #607d8b;
  width: 100%;
  padding: 80px;
}
.opaque {
  opacity: 0.5;
}

.field-container {
  min-width: 250px;
}

.embedded {
  padding-bottom: 50px;
}

.embedded .box.box-primary {
  border-top-color: transparent;
}

.embedded .box > .box-header {
  border-bottom-color: transparent;
}

.embedded .box > .box-body {
  height: calc(100% - 100px);
  overflow: visible;
}

.embedded .box > .box-footer {
  overflow: visible;
  padding-bottom: 50px;
}
</style>
